import Rails from "@rails/ujs"
Rails.start();

import Plotly from 'plotly.js-dist-min'

if (window.xs1 && window.ys1) {
    Plotly.newPlot("s1", [{ x: window.xs1, y: window.ys1, type: 'bar' }]);
}

if (window.xs2 && window.ys2) {
    Plotly.newPlot("s2", [{ x: window.xs2, y: window.ys2, type: 'bar' }]);

}
